import { Component, Input } from '@angular/core';
import { ButtonColorEnum } from '../../models/button-color.enum';

@Component({
  selector: 'gea-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() public color: ButtonColorEnum = ButtonColorEnum.Primary;
  @Input() public disabled: boolean;
  @Input() public icon: string;
  @Input() public type: string;
}
