import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalAnimationService {
  private animationStream$ = new BehaviorSubject<boolean>(true);

  public get onAnimationEnd(): Observable<boolean> {
    return this.animationStream$.asObservable();
  }

  public setAnimationStart(): void {
    this.animationStream$.next(false);
  }

  public setAnimationEnd(): void {
    this.animationStream$.next(true);
  }
}
