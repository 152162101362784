import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { APP_CONFIG, IAppConfig } from '../../configs/app.config';
import { IGuestDetailsConfig, IProfileConfig } from '../../configs/customer.config';
import { EndpointsConfig } from '../../configs/endpoints.config';
import {
  defaultThemeColors,
  defaultThemeImages,
  ITheme,
  IThemeValue
} from '../../configs/theme.config';
import { IBaseResponse } from '../models/base-response';
import { ICustomerInfo } from '../models/customer-info';
import { ICustomerSettings } from '../models/customer-settings';
import { storageFields } from './storage/storage.definitions';
import { StorageService } from './storage/storage.service';

@Injectable({ providedIn: 'root' })
export class CustomerService {
  public profileConfig: Observable<IProfileConfig>;
  private profileConfigSubject: BehaviorSubject<IProfileConfig>;
  private customThemeColors: BehaviorSubject<IThemeValue> = new BehaviorSubject(
    defaultThemeColors.value
  );
  private customThemeImages: BehaviorSubject<IThemeValue> = new BehaviorSubject(
    defaultThemeImages.value
  );

  themeMock: ITheme = {
    value: {
      '--color-body-background': 'pink',
      '--color-primary': 'blue',
      '--color-accent': 'red',
      '--color-general-font': 'yellow',
      '--color-general-font-2': 'purple',
      '--color-header-background': 'blue',
      '--color-header-font': 'yellow',
      '--color-header-font-hover': 'red',
      '--color-info-header-background': '#000',
      '--color-info-header-font': 'yellow',
      '--color-icon-color-1': '#e8c1a3',
      '--color-table-contrast-color': '#ededed',
      '--color-carousel-background': 'red',
      '--color-carousel-dot': '#fff',
      '--color-carousel-active-dot': 'grey',
      '--color-positive-content': 'pink',
      '--color-neutral-content': 'grey',
      '--color-negative-content': 'black',
      '--color-white': '#fff'
    }
  };

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    @Inject(APP_CONFIG) private appConfig: IAppConfig
  ) {
    this.profileConfigSubject = new BehaviorSubject<IProfileConfig>({
      arrivalListConfig: {
        columns: appConfig.guestArrivalsColumnsOrder
      },
      guestDetailsConfig: {
        tabs: appConfig.guestDetails.tabsOrder
      }
    });
    this.profileConfig = this.profileConfigSubject.asObservable();
  }

  getUserCustomers(): Promise<ICustomerInfo> {
    return this.http
      .get<IBaseResponse<ICustomerInfo[]>>(
        `${this.appConfig.portalCoreUrl}${EndpointsConfig.customer.userCustomers}`
      )
      .toPromise()
      .then(({ data }) => {
        this.storage.set(storageFields.USER_CUSTOMERS, JSON.stringify(data));
        return data.find(d => d.name === this.appConfig.defaultUserCustomer) || data[0];
      });
  }

  getCustomerSettings(customerInfo: ICustomerInfo): Promise<ICustomerInfo> {
    return this.http
      .get<IBaseResponse<ICustomerSettings[]>>(
        `${this.appConfig.portalCoreUrl}${EndpointsConfig.customer.customerSettings}${customerInfo.code}`
      )
      .toPromise()
      .then(({ data }) => {
        this.storage.set(storageFields.USER_CUSTOMER, customerInfo.code);
        this.saveCustomerSettings(storageFields.CLOUD_MW_BASE_URL, data);
        this.saveCustomerSettings(storageFields.TOP_3_FACTS_AVAILABLE, data);
        this.saveCustomerSettings(storageFields.INTERNAL_MESSAGING_AVAILABLE, data);
        this.saveCustomerSettings(storageFields.EXTERNAL_MESSAGING_AVAILABLE, data);
        this.saveCustomerSettings(storageFields.IMPORTANT_ALERTS_AVAILABLE, data);
        this.saveCustomerSettings(storageFields.CUSTOM_THEME_COLORS, data);
        this.saveCustomerSettings(storageFields.CUSTOM_THEME_IMAGES, data);
        return customerInfo;
      });
  }

  get guestDetailsConfig(): IGuestDetailsConfig {
    return this.profileConfigSubject.value.guestDetailsConfig;
  }

  private saveCustomerSettings(field: storageFields | string, settings: ICustomerSettings[]): void {
    // Temporary (!) Only for testing purpose
    // settings.push({ code: 'customThemeColors', type: 0, value: this.themeMock.value });
    // settings.push({ code: 'customThemeImages', type: 0' value: { 'logo': 'path_URL' });

    const value = (settings.find(r => r.code === field) || { value: '' }).value;

    this.storage.set(field, typeof value === 'object' ? JSON.stringify(value) : value);

    if (field === storageFields.CUSTOM_THEME_COLORS && typeof value === 'object') {
      this.setCustomThemeColors(value);
    }

    if (field === storageFields.CUSTOM_THEME_IMAGES && typeof value === 'object') {
      this.setCustomThemeImages(value);
    }
  }

  getCustomThemeColors(): Observable<IThemeValue> {
    return this.customThemeColors.asObservable();
  }

  setCustomThemeColors(value: IThemeValue): void {
    return this.customThemeColors.next(value);
  }

  getCustomThemeImages(): Observable<IThemeValue> {
    return this.customThemeImages.asObservable();
  }

  setCustomThemeImages(value: IThemeValue): void {
    return this.customThemeImages.next(value);
  }
}
