import { Component, Input } from '@angular/core';
import { LoaderSizeEnum } from '../../models/loader-size.enum';

@Component({
  selector: 'gea-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  @Input() public size: LoaderSizeEnum = LoaderSizeEnum.Large;
}
