import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { CustomerService } from '../../../core/services/customer.service';
import { storageFields } from '../../../core/services/storage/storage.definitions';
import { StorageService } from '../../../core/services/storage/storage.service';

@Directive({
  selector: '[geaCustomTheme]'
})
export class ThemeDirective implements OnInit, OnDestroy {
  @Input() scoped = false;
  private destroySubject$: Subject<void> = new Subject();

  constructor(
    private elementRef: ElementRef,
    private customerService: CustomerService,
    private storage: StorageService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.customerService
      .getCustomThemeColors()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(value => {
        const cached = this.storage.get(storageFields.CUSTOM_THEME_COLORS);

        return this.setThemeProperties(cached ? JSON.parse(cached) : value);
      });
    this.customerService
      .getCustomThemeImages()
      .pipe(take(1))
      .subscribe(images => {
        const cached = this.storage.get(storageFields.CUSTOM_THEME_IMAGES);

        if (JSON.stringify(images) === cached) {
          return;
        }

        return this.customerService.setCustomThemeImages(cached ? JSON.parse(cached) : images);
      });
  }

  ngOnDestroy(): void {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  setThemeProperties(theme: { [key: string]: string }) {
    const element = this.getElement();

    for (const [key, value] of Object.entries(theme || {})) {
      element.style.setProperty(key, value);
    }
  }

  getElement() {
    return this.scoped ? this.elementRef.nativeElement : this.document.body;
  }
}
