import { Component, Inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { APP_CONFIG, IAppConfig } from './configs/app.config';
import { slideInAnimation } from './shared/animations';
import { GlobalAnimationService } from './core/services/global-animation.service';

@Component({
  selector: 'gea-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation]
})
export class AppComponent {
  title = 'GEA';
  animatinDuration;

  constructor(
    private globalAnimationService: GlobalAnimationService,
    translate: TranslateService,
    @Inject(APP_CONFIG) private appConfig: IAppConfig
  ) {
    translate.addLangs(appConfig.languages);
    translate.setDefaultLang(appConfig.defaultLanguage);
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  public animStart(event) {
    this.globalAnimationService.setAnimationStart();
  }

  public animEnd(event) {
    this.globalAnimationService.setAnimationEnd();
  }
}
