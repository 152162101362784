import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterTypeEnum, ICheckboxOptions, IFilterConfig } from '../../../configs/filter.config';
import { FILTER_CONFIG } from '../../../core/core.module';
import { IFilter } from '../../../core/models/filter';
import { IRangeValues } from '../../../core/models/i-range-values';
import { IReservation } from '../../../core/models/reservation';
import { ButtonColorEnum } from '../../models/button-color.enum';
import { FilterReservationsPipe } from '../../pipes/filter-reservations.pipe';
import { dateFormat } from '../../shared.consts';

@Component({
  selector: 'gea-filters-tooltip',
  templateUrl: './filters-tooltip.component.html',
  styleUrls: ['./filters-tooltip.component.scss']
})
export class FiltersTooltipComponent implements OnInit, OnDestroy {
  @Input() public open: boolean = false;
  @Input() public requesting: boolean;

  @Output() public closeFilter: EventEmitter<null> = new EventEmitter<null>();
  @Output() public submitChanges: EventEmitter<IFilter> = new EventEmitter<IFilter>();
  @Output() public filterAmount: EventEmitter<number> = new EventEmitter<number>();

  public filterType: typeof FilterTypeEnum = FilterTypeEnum;
  public buttonColor: typeof ButtonColorEnum = ButtonColorEnum;
  public pipeResult: number;
  public checkboxOptions: { [key: string]: ICheckboxOptions[] };
  public readonly form: FormGroup;

  private currentDate: string = moment().format(dateFormat);
  private maxOfRangeValues: IRangeValues = {};
  private destroySubject$: Subject<void> = new Subject();
  private reservations: IReservation[];

  get rangeValues(): IRangeValues {
    return this.maxOfRangeValues;
  }

  get options() {
    return this.checkboxOptions;
  }

  @Input('dataSource') set maxRangeValues(values: IReservation[]) {
    if (!this.requesting) {
      this.reservations = values;
      this.pipeResult = values.length;
      this.clearFilters();
      this.getCheckboxOptions(values);
    }

    this.maxOfRangeValues = this.filters
      .map(filter =>
        filter.type === FilterTypeEnum.Range && filter.name !== 'arrivalDate'
          ? {
              [filter.name]: values.length
                ? Math.max(...values.map(value => value[filter.name]))
                : 0
            }
          : null
      )
      .filter(Boolean)
      .reduce((all, range) => ({ ...all, ...range }));
  }

  @Input('dateChange') set dateChange(values: Observable<string>) {
    values.pipe(takeUntil(this.destroySubject$)).subscribe(value => {
      if (value !== this.currentDate) {
        this.clearFilters();
        this.submitChanges.emit(this.form.value);
      }
    });
  }

  constructor(
    @Inject(FILTER_CONFIG) public filters: IFilterConfig[],
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private filterPipe: FilterReservationsPipe
  ) {
    this.form = this.fb.group({});
    this.prepareFilterForm();
  }

  public ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroySubject$)).subscribe((formValue: IFilter) => {
      this.countFilters(formValue);
      this.pipeResult = this.filterPipe.transform(
        this.reservations,
        this.getFilterValues(formValue)
      ).length;
    });
  }

  public getCheckboxOptions(data: IReservation[]) {
    const options: { [key: string]: ICheckboxOptions[] } = this.filters
      .map(filter => {
        return filter.type === FilterTypeEnum.Checkbox
          ? data.length && filter.name === 'room'
            ? {
                room: data.map(value => {
                  return {
                    label: value[filter.name],
                    value: value[filter.name]
                  };
                })
              }
            : { [filter.name]: filter.options }
          : null;
      })
      .filter(Boolean)
      .flat()
      .reduce((status, room) => ({ ...status, ...room }));

    this.checkboxOptions = Object.entries(options)
      .map(([key, values]) => ({
        key,
        values: Object.values(
          values
            .filter(element => element.value !== 'N/A')
            .reduce((prev, curr) => ({ ...prev, [curr.value]: curr }), {})
        )
      }))
      .reduce((prev, curr) => ({ ...prev, [curr.key]: curr.values }), {});

    this.getCheckboxFormControls();
  }

  public submit(): void {
    this.submitChanges.emit(this.getFilterValues(this.form.value));
    this.closeFilter.emit();
  }

  public ngOnDestroy(): void {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  public onClose(): void {
    this.closeFilter.emit();
  }

  public resetFilters(): void {
    for (const filter of this.filters) {
      filter.type === FilterTypeEnum.Checkbox
        ? this.form.get(filter.name).reset()
        : this.form
            .get(filter.name)
            .reset(
              this.maxOfRangeValues[filter.name]
                ? [filter.range.floor, this.maxOfRangeValues[filter.name]]
                : [filter.range.floor, filter.range.ceil]
            );
    }

    this.submitChanges.emit(this.getFilterValues(this.form.value));
  }

  private clearFilters(): void {
    for (const filter of this.filters) {
      filter.type === FilterTypeEnum.Checkbox
        ? this.clearFormArray(this.form.get(filter.name) as FormArray)
        : this.form.get(filter.name).reset([filter.range.floor, filter.range.ceil]);
    }
  }

  private clearFormArray(formArray: FormArray): void {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  private getFilterValues(data: IFilter) {
    return Object.values(this.filters)
      .map(filter => ({
        key: filter.name,
        filter:
          filter.type !== FilterTypeEnum.Checkbox
            ? data[filter.name]
            : data[filter.name]
                .map((value: string | boolean, i: number) => {
                  return value ? this.checkboxOptions[filter.name][i].value : null;
                })
                .filter(Boolean)
      }))
      .reduce((filters, { key, filter }) => ({ ...filters, [key]: filter }), {});
  }

  private createControl(): FormControl {
    return this.fb.control('');
  }

  private addControl(name: string): void {
    const items = this.form.get(name) as FormArray;
    items.push(this.createControl());
  }

  private prepareFilterForm(): void {
    let control: FormControl | FormArray;

    this.filters.forEach((filter: IFilterConfig) => {
      filter.type === FilterTypeEnum.Checkbox
        ? (control = this.fb.array([]))
        : (control = this.fb.control([filter.range.floor, filter.range.ceil]));

      this.form.addControl(filter.name, control);
    });
  }

  private getCheckboxFormControls(): void {
    this.filters.map(filter => {
      if (filter.type === FilterTypeEnum.Checkbox) {
        if (this.checkboxOptions[filter.name].length) {
          this.checkboxOptions[filter.name].forEach(() => {
            this.addControl(filter.name);
          });
        }
      }
    });
  }

  private countFilters(formValue: IFilter) {
    let counter = 0;

    for (const filter of this.filters) {
      if (filter.type === FilterTypeEnum.Checkbox) {
        const res = formValue[filter.name].filter(Boolean);
        counter += Math.min(1, res.length);
      }
      if (filter.type === FilterTypeEnum.Range) {
        if (
          formValue[filter.name][0] !== filter.range.floor ||
          (formValue[filter.name][1] !== filter.range.ceil &&
            formValue[filter.name][1] !== this.maxOfRangeValues[filter.name])
        ) {
          counter++;
        }
      }
    }

    this.filterAmount.emit(counter);
  }
}
