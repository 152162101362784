export enum storageFields {
  USER_EMAIL = 'userEmail',
  USER_CUSTOMERS = 'userCustomers',
  USER_CUSTOMER = 'userCustomer',
  PREDEFINED_PREFERENCES = 'predefinedPreferences',
  RESERVATIONS = 'reservations',
  RESERVATION_DETAILS = 'reservationsDetails',
  RESERVATION_DETAILS_MESSAGES = 'reservationDetailsMessages',
  LIFETIME_VALUES = 'lifetimeValues',
  COLUMNS = 'columns',
  AUTH0_CLIENT_ID = 'ClientId',
  AUTH0_DOMAIN = 'Domain',
  AUTH0_FEDERATED = 'Federated',
  RESOURCE_ACCESS_TOKEN = 'resourceAccessToken',
  ORIGIN_URL = 'originUrl',
  CLOUD_MW_BASE_URL = 'cloudMwBaseUrl',
  TOP_3_FACTS_AVAILABLE = 'top3FactsAvailable',
  INTERNAL_MESSAGING_AVAILABLE = 'internalMessagingAvailable',
  EXTERNAL_MESSAGING_AVAILABLE = 'externalMessagingAvailable',
  IMPORTANT_ALERTS_AVAILABLE = 'importantAlertsAvailable',
  CUSTOM_THEME_COLORS = 'customThemeColors',
  CUSTOM_THEME_IMAGES = 'customThemeImages',
  HOTELS_LIST = 'hotelsList',
  SELECTED_HOTEL = "selectedHotel" 
}

export interface ICacheNotification {
  date: string;
  type: cacheNotificationsTypes;
}

export enum cacheNotificationsTypes {
  INITIAL = 'initial',
  UPDATE = 'update'
}
