import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { IPreferenceOption } from '../../../modules/guest-details/models/preference';

@Component({
  selector: 'gea-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() public options: IPreferenceOption[];
  @Input() public placeholder: string;
  @Input() public value: string;
  @Input() public isShadow: boolean = false;

  @Output() public selectedOption: EventEmitter<string> = new EventEmitter();

  public show: boolean = false;
  public optionName: string;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.optionName = (this.options.find(o => o.Value === this.value) || { Name: '' }).Name;
  }

  public toggleSelect(): void {
    if (this.options.length > 1) {
      this.show = !this.show;
    }
  }

  public onOptionSelect(option: IPreferenceOption): void {
    this.optionName = option.Name;
    this.show = false;
    this.selectedOption.emit(option.Value);
  }

  public getOptionName(): string {
    return this.optionName || this.options[0].Name;
  }

  @HostListener('document:click', ['$event'])
  private clickOut(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.hide();
    }
  }

  private hide(): void {
    this.show = false;
  }
}
