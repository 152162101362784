import { InjectionToken } from '@angular/core';

export let ROUTES_CONFIG = new InjectionToken<IRouteConfig>('routes.config');

class RoutesNames {
  login = 'login';
  arrivalList = 'arrival-list';
  guestDetails = 'guest-details';
  error404 = '404';
}

class Routes {
  login: string;
  arrivalList: string;
  guestDetails: (id: string) => string;
  error404: string;
  constructor(rn: RoutesNames) {
    this.login = `/${rn.login}`;
    this.arrivalList = `/${rn.arrivalList}`;
    this.guestDetails = (id: string) => `/${rn.guestDetails}/${id}`;
    this.error404 = `/${rn.error404}`;
  }
}

export interface IRouteConfig {
  routesNames: RoutesNames;
  routes: Routes;
}

const routesNames = new RoutesNames();

export const RoutesConfig: IRouteConfig = {
  routesNames,
  routes: new Routes(routesNames)
};
