import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InjectionToken, NgModule, Optional, SkipSelf } from '@angular/core';

import { FiltersConfig, IFilterConfig } from '../configs/filter.config';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { JwtInterceptor } from './helpers/jwt.interceptor';

export const FILTER_CONFIG = new InjectionToken<IFilterConfig>('filter-config');

@NgModule({
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: FILTER_CONFIG, useValue: FiltersConfig }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        `CoreModule has already been loaded. Import Core modules in the AppModule only.`
      );
    }
  }
}
