import { Pipe, PipeTransform } from '@angular/core';

import { IFilter } from '../../core/models/filter';
import { IReservation } from '../../core/models/reservation';

@Pipe({
  name: 'filterReservations'
})
export class FilterReservationsPipe implements PipeTransform {
  transform(items: IReservation[], ...args: IFilter[]): IReservation[] {
    return !items ? [] : items.filter((item: IReservation) => {
      for (const [key, value] of Object.entries(args[0] || {})) {
        if (!Array.isArray(value) || !value.length) {
          continue;
        }

        const itemValue = item[key];

        if (typeof value[0] === 'string' && !value.includes(itemValue)) {
          return false;
        }

        if (typeof value[0] === 'number' && (itemValue < value[0] || itemValue > value[1])) {
          return false;
        }
      }

      return true;
    });
  }
}
