import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { OptionsInput } from '@fullcalendar/core/types/input-types';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import * as moment from 'moment';

import { APP_CONFIG, IAppConfig } from '../../../configs/app.config';
import { dateFormat } from '../../shared.consts';

@Component({
  selector: 'gea-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  @ViewChild('fullCalendar', { static: false }) set calendarComponent(
    calendarComponent: FullCalendarComponent
  ) {
    if (calendarComponent) {
      const calendar = calendarComponent.getApi();
      calendar.gotoDate(this.currentDate);
      this.element = calendarComponent['element'].nativeElement;

      const dayTopElements = this.getElement('fc-day-top');

      const date = Array.from(dayTopElements).filter(
        (item: HTMLElement) => item.dataset.date === this.date
      )[0];
      date.classList.add('selected-day');

      this.addBtnClass();
    }
  }

  public element;
  public yesterday: string = moment(new Date())
    .add(-1, 'days')
    .format(dateFormat);
  public tomorrow: string = moment(new Date())
    .add(1, 'days')
    .format(dateFormat);
  public today = moment().format(dateFormat);
  public calendarPlugins = [dayGridPlugin, interactionPlugin]; // important!
  public options: OptionsInput;

  private open: boolean;
  private date: string;

  public expectedOccupancy;

  get openCalendar(): boolean {
    return this.open;
  }

  get currentDate() {
    return this.date;
  }

  @Input('open') set openCal(value: boolean) {
    this.open = value;
    this.date = this.route.snapshot.queryParams['date']
      ? this.route.snapshot.queryParams['date']
      : this.today;
  }

  @Output() public closeCalendar: EventEmitter<null> = new EventEmitter<null>();
  @Output() public selectedDate: EventEmitter<string> = new EventEmitter<string>();

  constructor(private route: ActivatedRoute, @Inject(APP_CONFIG) private appConfig: IAppConfig) {
    this.expectedOccupancy = appConfig.expectedOccupancy;
  }

  ngOnInit(): void {
    this.options = {
      customButtons: {
        yesterday: {
          text: 'Yesterday',
          click: element => {
            this.onSelect(this.yesterday);
          }
        },
        now: {
          text: 'Today',
          click: element => {
            this.onSelect(this.today);
          }
        },
        tomorrow: {
          text: 'Tomorrow',
          click: element => {
            this.onSelect(this.tomorrow);
          }
        }
      },
      header: {
        left: 'prev, next',
        right: 'yesterday now tomorrow',
        center: 'title'
      }
    };
  }

  public handleDateClick(arg): void {
    this.onSelect(arg.dateStr);
  }

  public onSelect(date: string) {
    this.selectedDate.emit(date);
    this.onClose();
  }

  public onClose(): void {
    this.closeCalendar.emit();
  }

  private addBtnClass(): void {
    if (this.date === this.today) {
      const todayElement = this.getElement('fc-now-button');
      todayElement[0].classList.add('selected-btn');
    }

    if (this.date === this.yesterday) {
      const todayElement = this.getElement('fc-yesterday-button');
      todayElement[0].classList.add('selected-btn');
    }

    if (this.date === this.tomorrow) {
      const todayElement = this.getElement('fc-tomorrow-button');
      todayElement[0].classList.add('selected-btn');
    }
  }

  private getElement(className: string): HTMLElement[] {
    return this.element.getElementsByClassName(className) as HTMLElement[];
  }
}
