import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { IRouteConfig, ROUTES_CONFIG } from '../../configs/routes.config';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    @Inject(ROUTES_CONFIG) public routesConfig: IRouteConfig
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authenticationNotRequired(route.routeConfig.path)) {
      return true;
    } else {
      this.authenticationService.logout();
      return false;
    }
  }

  private authenticationNotRequired(currentPath: string): boolean {
    return (
      currentPath === this.routesConfig.routesNames.login ||
      currentPath === this.routesConfig.routesNames.error404 ||
      this.authenticationService.authenticated()
    );
  }
}
