import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Auth0Lock from 'auth0-lock';
import { BehaviorSubject, Observable } from 'rxjs';

import { APP_CONFIG, IAppConfig } from '../../../configs/app.config';
import { EndpointsConfig } from '../../../configs/endpoints.config';
import { IRouteConfig, ROUTES_CONFIG } from '../../../configs/routes.config';
import { storageFields } from '../storage/storage.definitions';
import { StorageService } from '../storage/storage.service';
import { JwtHelper } from './jwt-helper';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private AUTH0_CLIENT_ID: string;
  private AUTH0_DOMAIN: string;
  private AUTH0_FEDERATED: boolean = false;
  private lock: Auth0Lock;
  private emailAddressSubject: BehaviorSubject<string>;
  public emailAddress: Observable<string>;
  constructor(
    private storage: StorageService,
    private http: HttpClient,
    private router: Router,
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    @Inject(ROUTES_CONFIG) public routesConfig: IRouteConfig
  ) {
    storage.set(storageFields.ORIGIN_URL, location.origin);
    this.emailAddressSubject = new BehaviorSubject<string>(
      storage.get(storageFields.USER_EMAIL) || ''
    );
    this.emailAddress = this.emailAddressSubject.asObservable();
  }

  public getAuthByEmail(email: string): Observable<any> {
    this.emailAddressSubject.next(email);
    return this.http.get(
      `${this.appConfig.portalCoreUrl}${EndpointsConfig.authentication.userProviderWithEmail}${email}`
    );
  }

  public getAuthByOrigin(): Observable<any> {
    const origin = window.location.hostname;
    return this.http.get(
      `${this.appConfig.portalCoreUrl}${EndpointsConfig.authentication.originProviders}${origin}`
    );
  }

  public setEnvironment(data: any): Promise<void> {
    data.settings.forEach(element => {
      switch (element.code) {
        case storageFields.AUTH0_CLIENT_ID:
          this.AUTH0_CLIENT_ID = element.value;
          break;
        case storageFields.AUTH0_DOMAIN:
          this.AUTH0_DOMAIN = element.value;
          break;
        case storageFields.AUTH0_FEDERATED:
          this.AUTH0_FEDERATED = element.value;
          break;
      }
    });
    this.storage.set(storageFields.AUTH0_CLIENT_ID, this.AUTH0_CLIENT_ID);
    this.storage.set(storageFields.AUTH0_DOMAIN, this.AUTH0_DOMAIN);
    this.storage.set(storageFields.AUTH0_FEDERATED, this.AUTH0_FEDERATED.toString());
    return this.login();
  }

  public login(): Promise<void> {
    this.lock = this.prepareLoginAuth0Lock();
    return new Promise((resolve, reject) => {
      this.lock.on('authenticated', (authResult: any) => {
        const form = {
          clientId: this.AUTH0_CLIENT_ID,
          token: authResult.accessToken
        };

        this.http
          .post(
            `${this.appConfig.portalCoreUrl}${EndpointsConfig.authentication.securitySettings}`,
            form
          )
          .subscribe((res: any) => {
            this.onTokenResponseReceived(res)
              .then(() => resolve())
              .catch(err => reject(err));
          });
      });
      this.lock.show();
    });
  }

  public authenticated(): boolean {
    const token = this.storage.get(storageFields.RESOURCE_ACCESS_TOKEN) as string;
    if (!token) {
      return false;
    }
    const claims = JwtHelper.decodeToken(token);

    const expiryDate = new Date(claims.exp * 1000);
    const currentDate = new Date();
    return expiryDate > currentDate;
  }

  private prepareLoginAuth0Lock(): Auth0Lock {
    return new Auth0Lock(this.AUTH0_CLIENT_ID, this.AUTH0_DOMAIN, {
      closable: false,
      auth: {
        params: {
          scope: 'openid email user_metadata app_metadata'
        },
        redirect: false,
        responseType: 'token id_token'
      },
      autoclose: true,
      rememberLastLogin: false,
      prefill: { email: this.emailAddress }
    });
  }

  private onTokenResponseReceived(res: any): Promise<void> {
    return new Promise((resolve, reject) => {
      if (res.success) {
        const { email = '' } = JwtHelper.decodeToken(res.data.accessToken);
        this.storage.set(storageFields.RESOURCE_ACCESS_TOKEN, res.data.accessToken);
        this.storage.set(storageFields.USER_EMAIL, email);
        this.emailAddressSubject.next(email);
        this.lock.hide();
        resolve();
      } else {
        const originUrl = this.storage.get(storageFields.ORIGIN_URL);
        const redirectUrl = `${originUrl}${this.routesConfig.routes.login}`;
        this.lock.hide();
        setTimeout(() => {
          if (this.storage.get(storageFields.AUTH0_FEDERATED)) {
            this.lock.logout({ returnTo: redirectUrl, federated: true });
          } else {
            this.lock.logout({ returnTo: redirectUrl });
          }
        }, 5000);
        reject();
      }
    });
  }

  logout() {
    if (!this.authenticated) {
      return;
    }

    const clientId = this.storage.get(storageFields.AUTH0_CLIENT_ID);
    const domain = this.storage.get(storageFields.AUTH0_DOMAIN);
    const originUrl = this.storage.get(storageFields.ORIGIN_URL);

    if (clientId && domain) {
      this.lock = new Auth0Lock(clientId, domain);
      const redirectUrl = `${originUrl}${this.routesConfig.routes.login}?message=${encodeURIComponent('loginPage.loggedout')}`;

      if (this.storage.get(storageFields.AUTH0_FEDERATED)) {
        this.lock.logout({ returnTo: redirectUrl, federated: true });
      } else {
        this.lock.logout({ returnTo: redirectUrl });
      }
    } else {
      this.router.navigate([this.routesConfig.routes.login]);
    }
    this.emailAddressSubject.next('');
    this.storage.clear();
  }
}
