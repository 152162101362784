import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'gea-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
  @Output() public searchValue: EventEmitter<string> = new EventEmitter<string>();

  public onSearch(e): void {
    this.searchValue.emit(e.target.value.toLowerCase());
  }
}
