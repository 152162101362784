import { Pipe, PipeTransform } from '@angular/core';

import { IReservation } from '../../core/models/reservation';

@Pipe({
  name: 'searchReservations'
})
export class SearchReservationsPipe implements PipeTransform {
  transform(items: IReservation[], ...args: string[]): IReservation[] {
    return items.filter((item: IReservation) => {
      return (
        (item.room && item.room.toLowerCase().includes(args[0])) ||
        (item.loyalty && item.loyalty.toString().includes(args[0])) ||
        (item.name && item.name.toLowerCase().includes(args[0]))
      );
    });
  }
}
