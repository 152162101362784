import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IToastMessage } from '../models/toast/toast-message';
import { ToastMessageTypeEnum } from '../models/toast/toast-message-type.enum';

@Injectable({
  providedIn: 'root'
})
export class ToastMessagesService {
  private messages: Array<IToastMessage> = [];
  private emitter: EventEmitter<IToastMessage[]> = new EventEmitter();
  private refreshEmitter: EventEmitter<boolean> = new EventEmitter();

  public add(content: string, type: ToastMessageTypeEnum): void {
    this.messages.push({ content, type });
    this.emitter.emit(this.messages);

    if (type !== ToastMessageTypeEnum.Update) {
      setTimeout(() => {
        this.messages.splice(0, 1);
        this.emitter.emit(this.messages);
      }, 5000);
    }
  }

  public close(i: number): void {
    this.messages.splice(i, 1);
    this.emitter.emit(this.messages);
  }

  public refresh(): void {
    this.refreshEmitter.emit(true);
  }

  public get(): Observable<IToastMessage[]> {
    return this.emitter;
  }

  public isRefresh(): Observable<boolean> {
    return this.refreshEmitter;
  }
}
