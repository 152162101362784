import { Options } from 'ng5-slider';
import { environment } from '../../environments/environment';

export enum FilterTypeEnum {
  Checkbox = 'checkbox',
  Range = 'range'
}

export const FiltersConfig: IFilterConfig[] = [
  {
    name: 'status',
    type: FilterTypeEnum.Checkbox,
    options: [
      {
        label: 'Checked-in',
        value: 'I'
      },
      {
        label: 'Expected',
        value: 'R'
      },
      {
        label: 'No Show',
        value: 'XNS'
      },
      {
        label: 'Canceled',
        value: 'X'
      },
      {
        label: 'History',
        value: 'H'
      }
    ]
  },
  {
    name: 'arrivalDate',
    type: FilterTypeEnum.Range,
    range: {
      floor: 0,
      ceil: 1440,
      step: 60
    },
    isDate: true
  },
  {
    name: 'nights',
    type: FilterTypeEnum.Range,
    range: {
      floor: 1,
      ceil: Infinity,
      step: 1
    }
  },
  {
    name: 'lifetime-value',
    type: FilterTypeEnum.Range,
    range: {
      floor: 0,
      ceil: 25000,
      step: 10
    },
    currency: environment.currency
  },
  {
    name: 'room',
    type: FilterTypeEnum.Checkbox,
    options: []
  }
];

export interface IFilterConfig {
  name: string;
  type: FilterTypeEnum;
  options?: ICheckboxOptions[];
  range?: Options;
  currency?: string;
  isDate?: boolean;
}

export interface ICheckboxOptions {
  label: string;
  value: string;
}
