import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import Dexie from 'dexie';
import { ILifetimeValue } from '../../../modules/guest-details/models/lifetime-value';
import { IMessagesResponse } from '../../../modules/guest-details/models/messages';
import { reservationDetailsTypes } from '../../../modules/guest-details/models/reservation-details-types.enum';

import { Nullable } from '../../../shared/shared.types';
import { IReservationResponse } from '../../models/reservation';
import { cacheNotificationsTypes, ICacheNotification, storageFields } from './storage.definitions';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private reservationsCacheChanges$: Subject<ICacheNotification> = new Subject<
    ICacheNotification
  >();

  constructor(private route: ActivatedRoute) {}

  get(key: storageFields): string {
    return localStorage.getItem(key);
  }

  set(key: string, value: string): void {
    return localStorage.setItem(key, value);
  }

  remove(key: storageFields): void {
    return localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
    this.clearIndexDB();
  }

  public async getReservationsByDate(hotelCode: string, date: string): Promise<Nullable<{ value: IReservationResponse[]; completed: boolean; toSkip: number; }>> {
    return await this.retreiveFromIndexDB(storageFields.RESERVATIONS, 'date,data', async store => {
      if (store) {
        const rawReservations = await store.get({ date });
        return rawReservations && rawReservations.data ? JSON.parse(rawReservations.data)[hotelCode] : null;
      }
      return null;
    });
  }

  public async getReservationById(id: number): Promise<Nullable<IReservationResponse>> {
    return await this.retreiveFromIndexDB(storageFields.RESERVATIONS, 'date,data', async store => {
      let ultimateItem: Nullable<IReservationResponse> = null;

      await store.each((item: any) => {
        let reservations: { [hotelCode: string]: { value: IReservationResponse[]; } };
        if (typeof item.data === 'string') {
          reservations = JSON.parse(item.data);
          if (ultimateItem) {
            return;
          }
          ultimateItem = Object.values(reservations)
            .reduce((acc, cur) => acc.concat(cur.value), [])
            .find((item: IReservationResponse) => item.Id === id);
        } else {
          return;
        }
      });

      return ultimateItem;
    });
  }

  public async saveReservations(hotelCode: string, date: string, value: IReservationResponse[], completed: boolean = true, toSkip: number = 0): Promise<void> {
    return await this.retreiveFromIndexDB(storageFields.RESERVATIONS, 'date,data', async store => {
      const existingCache = await store.get({ date });

      if (existingCache && typeof existingCache.data === 'string') {
        const existingCacheData = JSON.parse(existingCache.data);
        existingCacheData[hotelCode] = {
          value,
          completed,
          toSkip
        };
        await store.put({ date, data: JSON.stringify(existingCacheData) });
      } else {
        const cacheObject = {
          [hotelCode]: {
            value,
            completed,
            toSkip
          }
        };
        await store.put({ date, data: JSON.stringify(cacheObject) });
      }
    });
  }

  public getReservationsCacheChanges(): Observable<ICacheNotification> {
    return this.reservationsCacheChanges$.asObservable();
  }

  public async saveReservationDetails(id: number, type: reservationDetailsTypes, data: string): Promise<void> {
    switch (type) {
      case reservationDetailsTypes.MESSAGES:
        await this.saveReservationDetails_Messages(id, data as string);
        break;
    }
  }

  public async getReservationDetails(id: number, type: reservationDetailsTypes): Promise<IMessagesResponse[]> {
    switch (type) {
      case reservationDetailsTypes.MESSAGES:
        return await this.getReservationDetails_Messages(id);
    }
  }

  public async saveLifetimeValues(personId: number, values: ILifetimeValue): Promise<void> {
    return await this.retreiveFromIndexDB(storageFields.LIFETIME_VALUES, 'personId,data', async store => {
      await store.put({ personId, data: JSON.stringify(values) });
    });
  }

  public async getLifetimeValues(personId: number): Promise<ILifetimeValue> {
    return await this.retreiveFromIndexDB(storageFields.LIFETIME_VALUES, 'personId,data', async store => {
      const existingCache = await store.get({ personId });

      if (existingCache && typeof existingCache.data === 'string') {
        return JSON.parse(existingCache.data);
      }

      return null;
    });
  }

  private emitReservationsCacheChanges(content: ICacheNotification): void {
    return this.reservationsCacheChanges$.next(content);
  }

  private async getReservationDetails_Messages(id: number): Promise<IMessagesResponse[]> {
    return await this.retreiveFromIndexDB(storageFields.RESERVATION_DETAILS_MESSAGES, 'id,value', async store => {
      const existingCache = await store.get(id);

      if (existingCache && typeof existingCache.value === 'string') {
        const _existingCache = JSON.parse(existingCache.value);
        if (_existingCache[id]) {
          return JSON.parse(_existingCache[id]);
        }
        return null;
      }

      return null;
    });
  }

  private async saveReservationDetails_Messages(id: number, data: string): Promise<void> {
    return await this.retreiveFromIndexDB<void>(storageFields.RESERVATION_DETAILS_MESSAGES, 'id,value', async store => {
      const existingCache = await store.get(id);
      let prepared = {};

      if (existingCache && typeof existingCache.value === 'string') {
        prepared = {
          ...JSON.parse(existingCache.value)
        };
      }

      prepared[id] = data;
      await store.put({ id, value: JSON.stringify(prepared) });
    });
  }

  private async retreiveFromIndexDB<T>(dbname: string, schema: string, cb: (store: any) => Promise<T>): Promise<T> {
    const db = new Dexie(dbname);
    db.version(1).stores({
      [dbname]: schema
    });
    const store = db[dbname];
    const result = await cb(store);
    db.close();
    return result;
  }

  private async clearIndexDB(): Promise<void> {
    const dbs = [
      storageFields.RESERVATIONS, 
      storageFields.RESERVATION_DETAILS_MESSAGES,
      storageFields.LIFETIME_VALUES
    ].map(field => new Dexie(field));
    
    await Promise.all(dbs.map(db => db.delete()));
  }
}
