import { Pipe, PipeTransform } from '@angular/core';
import { IFilterConfig } from '../../configs/filter.config';

@Pipe({
  name: 'inputValueConverter'
})
export class InputValueConverterPipe implements PipeTransform {
  transform(value: number, args: IFilterConfig): number | string {
    if (args.hasOwnProperty('currency')) {
      return `${args.currency}${value.toFixed(2)}`;
    } else if (args.isDate) {
      const hours = Math.floor(value / 60);
      const hoursStr = ('0' + Math.floor(value / 60)).substr(-2);
      const minutes = ('0' + Math.floor(value - hours * 60)).substr(-2);

      return `${hoursStr}:${minutes}`;
    }

    return value;
  }
}
