import { Component, Host, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

import { ICheckboxOptions } from '../../../configs/filter.config';

@Component({
  selector: 'gea-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  @Input() value: string;
  @Input() name: string;
  @Input() controlName?: string;
  @Input() type: string = 'primary';

  public formGroup: FormGroup;
  public preferencesOptions: ICheckboxOptions[];

  get labels(): ICheckboxOptions[] {
    return this.preferencesOptions;
  }

  @Input('labels') set preferencesLabels(value) {
    this.preferencesOptions = value.map(item => ({
      label: item.Name ? item.Name : item.label,
      value: item.Value ? item.Value : item.value
    }));
  }

  constructor(@Host() private formGroupDirective: FormGroupDirective) {}

  public ngOnInit(): void {
    this.formGroup = this.formGroupDirective.form;
  }
}
