import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

export const pageFadeAnimation = trigger('pageFadeAnimation', [
  transition('* => *', [
    query(':enter', [style({ opacity: 0 })], { optional: true }),
    query(':leave', [style({ opacity: 1 }), animate('0.1s', style({ opacity: 0 }))], {
      optional: true
    }),
    query(':enter', [style({ opacity: 0 }), animate('0.1s', style({ opacity: 1 }))], {
      optional: true
    })
  ])
]);

export const slideOutFadeInVertical = trigger('slideOutFadeInVertical', [
  state(
    'void',
    style({
      opacity: 0,
      marginBottom: '-48px'
    })
  ),
  state(
    '*',
    style({
      opacity: 1,
      marginBottom: '0'
    })
  ),
  transition('* <=> *', animate(400))
]);

export const slideInAnimation = trigger('routeAnimations', [
  transition('ArrivalListPage => GuestDetailsPage', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ left: '-100%' })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('400ms ease-out', style({ left: '100%' }))]),
      query(':enter', [animate('400ms ease-out', style({ left: '0%' }))])
    ]),
    query(':enter', animateChild())
  ]),
  transition('GuestDetailsPage => ArrivalListPage', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ right: '-100%' })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('400ms ease-out', style({ right: '100%' }))]),
      query(':enter', [animate('400ms ease-out', style({ right: '0%' }))])
    ]),
    query(':enter', animateChild())
  ])
]);
