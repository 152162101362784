import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { IHotelCode } from '../models/hotel-code';
import { StorageService } from './storage/storage.service';
import { storageFields } from './storage/storage.definitions';
import { EndpointsConfig } from '../../configs/endpoints.config';

@Injectable({
  providedIn: 'root'
})
export class HotelService {
  private _selectedHotel$ = new BehaviorSubject<IHotelCode>(null);
  private _hotelsList$ = new BehaviorSubject<IHotelCode[]>(null);
  private _cloudMwBaseUrl: string;

  constructor(
    private storage: StorageService,
    private http: HttpClient,
  ) {}

  public get selectedHotel(): Observable<IHotelCode> {
    return this._selectedHotel$.asObservable();
  }

  public setCurrentHotel(hotel: IHotelCode): void {
    this._selectedHotel$.next(hotel);
  }

  public setProprtyList(): void {
    this.setCloudMwBaseurl();
    this.getPropertyList().subscribe(list => {
      if (list && list.Data) {
        if (list.Data.length > 0) {
          this.storage.set(storageFields.HOTELS_LIST, JSON.stringify(list.Data));
          this._hotelsList$.next(list.Data);
        }
      }
    });
  }

  public getPropertyList(): any {
    return this.http.get(`${this._cloudMwBaseUrl}${EndpointsConfig.property.property}`);
  }

  private setCloudMwBaseurl(): void {
    this._cloudMwBaseUrl = this.storage.get(storageFields.CLOUD_MW_BASE_URL);
  }

  public getHotelsList(): Observable<IHotelCode[]> {
    return this._hotelsList$.asObservable();
  }
}
