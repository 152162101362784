import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { StorageService } from 'src/app/core/services/storage/storage.service';
import { storageFields } from 'src/app/core/services/storage/storage.definitions';
import { MessageService } from 'src/app/core/services/message.service';
import { IHotelCode } from 'src/app/core/models/hotel-code';
import { HotelService } from 'src/app/core/services/hotel.service';

@Component({
  selector: 'gea-hotel-selector',
  templateUrl: 'hotel-selector.component.html',
  styleUrls: ['hotel-selector.component.scss']
})
export class HotelSelectorComponent implements OnInit, OnDestroy {
  private _subscription: Subscription;
  private messages: any[] = [];
  public hideHotelSelector: boolean = true;
  public hotelsList: IHotelCode[];
  public selectedHotel: IHotelCode;
  public toggleHotelsList: boolean = false;

  constructor(
    private storageService: StorageService,
    private messageService: MessageService,
    private hotelService: HotelService
  ) {}

  public ngOnInit(): void {
    this.getHotelList();
    this._subscription = this.messageService.getMessage().subscribe(message => {
      if (message) {
        this.messages.push(message);
      } else {
        this.messages = [];
      }
    });
  }

  public ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  public toggleHotelList(force: boolean = false): void {
    if (this.selectedHotel || force) {
      this.toggleHotelsList = !this.toggleHotelsList;
    }
  }

  public changeHotel(hotel: IHotelCode): void {
    this.selectedHotel = hotel;
    this.storageService.set(storageFields.SELECTED_HOTEL, JSON.stringify(this.selectedHotel));
    this.hotelService.setCurrentHotel(this.selectedHotel);
    this.toggleHotelList(true);
  }

  private getHotelList(): void {
    this.hotelsList = JSON.parse(this.storageService.get(storageFields.HOTELS_LIST));
    this.selectedHotel = JSON.parse(this.storageService.get(storageFields.SELECTED_HOTEL));
    
    if (this.hotelsList) {
      this.afterHotelsFetched();
      if (this.selectedHotel) {
        this.hotelService.setCurrentHotel(this.selectedHotel);
        this.toggleHotelsList = false;
      }
    } else {
      const subscription = this.hotelService.getHotelsList()
        .pipe(
          filter(d => !!d)
        )
        .subscribe(hotels => {
          this.hotelsList = hotels;
          this.afterHotelsFetched();
          subscription.unsubscribe();
        });
    }
    
  }

  private afterHotelsFetched(): void {
    if (this.hotelsList && this.hotelsList.length == 1) {
      this.hideHotelSelector = true;
      this.selectedHotel = this.hotelsList[0];
      this.hotelService.setCurrentHotel(this.selectedHotel);
    } else if (this.hotelsList && this.hotelsList.length > 1) {
      this.hideHotelSelector = false;
      this.toggleHotelsList = true;
    }

    /*this.selectedHotel = JSON.parse(this.storageService.get(storageFields.SELECTED_HOTEL));
    this.hotelService.setCurrentHotel(this.selectedHotel);
    if (this.selectedHotel && this.selectedHotel.Code == null) {
      this.toggleHotelsList = true;
    }*/
  }
}
