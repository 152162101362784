import { Injectable } from '@angular/core';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/authentication/authentication.service';
import { storageFields } from '../services/storage/storage.definitions';
import { StorageService } from '../services/storage/storage.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private storage: StorageService,
    private authenticationService: AuthenticationService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authenticationService.authenticated()) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.storage.get(storageFields.RESOURCE_ACCESS_TOKEN)}`
        }
      });
    }

    return next.handle(request);
  }
}
