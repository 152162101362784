export interface ITheme {
  value: IThemeValue;
}

export interface IThemeValue {
  [key: string]: string;
}

export const defaultThemeColors: ITheme = {
  value: {
    '--color-body-background': '#f2f2f2',
    '--color-primary': '#363b6f',
    '--color-accent': '#a22405',
    '--color-general-font': '#000',
    '--color-general-font-2': '#999999',
    '--color-header-background': '#000',
    '--color-header-font': '#fff',
    '--color-header-font-hover': '#363b6f',
    '--color-info-header-background': '#363b6f',
    '--color-info-header-font': '#fff',
    '--color-icon-color-1': '#e8c1a3',
    '--color-table-contrast-color': '#ededed',
    '--color-carousel-background': '#faf4f2',
    '--color-carousel-dot': '#e1c8c2',
    '--color-carousel-active-dot': '#a22405',
    '--color-positive-content': '#17a319',
    '--color-neutral-content': '#f4a622',
    '--color-negative-content': '#a22404',
    '--color-white': '#fff'
  }
};

export const defaultThemeImages: ITheme = {
  value: {
    logo: '/assets/images/loews.svg'
  }
};
