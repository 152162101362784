import { Component, OnDestroy, OnInit } from '@angular/core';
import { IToastMessage } from '../../../core/models/toast/toast-message';
import { ToastMessageTypeEnum } from '../../../core/models/toast/toast-message-type.enum';
import { ToastMessagesService } from '../../../core/services/toast-messages.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { slideOutFadeInVertical } from '../../animations';
import { ButtonColorEnum } from '../../models/button-color.enum';

@Component({
  selector: 'gea-toast-messages',
  templateUrl: './toast-messages.component.html',
  styleUrls: ['./toast-messages.component.scss'],
  animations: [slideOutFadeInVertical]
})
export class ToastMessagesComponent implements OnInit, OnDestroy {
  public buttonColor: typeof ButtonColorEnum = ButtonColorEnum;
  public messages: IToastMessage[];
  public readonly messageType: typeof ToastMessageTypeEnum = ToastMessageTypeEnum;

  private destroySubject$: Subject<void> = new Subject();

  constructor(private toastMessagesService: ToastMessagesService) {}

  public ngOnInit(): void {
    this.toastMessagesService
      .get()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((messages: IToastMessage[]) => (this.messages = messages));
  }

  public getStatusClass(type: number): string {
    return ToastMessageTypeEnum[type].toLowerCase();
  }

  public ngOnDestroy(): void {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  public closeMessage(index: number) {
    this.toastMessagesService.close(index);
  }

  public refreshPage(i: number): void {
    this.toastMessagesService.refresh();
    this.closeMessage(i);
  }
}
