import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { FullCalendarModule } from '@fullcalendar/angular';
import { Ng5SliderModule } from 'ng5-slider';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CarouselModule } from 'primeng/carousel';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';

import { ButtonComponent } from './components/button/button.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { FiltersTooltipComponent } from './components/filters-tooltip/filters-tooltip.component';
import { HeaderComponent } from './components/header/header.component';
import { InputComponent } from './components/input/input.component';
import { LoaderComponent } from './components/loader/loader.component';
import { RangeSliderComponent } from './components/range-slider/range-slider.component';
import { SearchComponent } from './components/search/search.component';
import { SelectComponent } from './components/select/select.component';
import { ToastMessagesComponent } from './components/toast-messages/toast-messages.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { ThemeDirective } from './directives/theme/theme.directive';
import { FilterReservationsPipe } from './pipes/filter-reservations.pipe';
import { InitialsPipe } from './pipes/initials.pipe';
import { InputValueConverterPipe } from './pipes/input-value-converter.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { SearchReservationsPipe } from './pipes/search-reservations.pipe';
import { HotelSelectorComponent } from './components/hotel-selector/hotel-selector.component';

@NgModule({
  declarations: [
    ButtonComponent,
    HeaderComponent,
    LoaderComponent,
    InputComponent,
    CheckboxComponent,
    RangeSliderComponent,
    FiltersTooltipComponent,
    SearchComponent,
    InputValueConverterPipe,
    FilterReservationsPipe,
    InitialsPipe,
    SearchReservationsPipe,
    CalendarComponent,
    TopbarComponent,
    SelectComponent,
    ToastMessagesComponent,
    ReplacePipe,
    ReplacePipe,
    ThemeDirective,
    CarouselComponent,
    HotelSelectorComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    InputTextModule,
    FormsModule,
    Ng5SliderModule,
    CheckboxModule,
    FullCalendarModule,
    PerfectScrollbarModule,
    CarouselModule
  ],
  exports: [
    ButtonComponent,
    HeaderComponent,
    LoaderComponent,
    TranslateModule,
    InputComponent,
    FormsModule,
    ReactiveFormsModule,
    RangeSliderComponent,
    CheckboxComponent,
    FilterReservationsPipe,
    InitialsPipe,
    SearchReservationsPipe,
    SearchComponent,
    CalendarComponent,
    SearchComponent,
    FiltersTooltipComponent,
    InputValueConverterPipe,
    TopbarComponent,
    ToastMessagesComponent,
    SelectComponent,
    ReplacePipe,
    CarouselComponent,
    ThemeDirective
  ],
  providers: [FilterReservationsPipe]
})
export class SharedModule {}
