// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  portalCoreUrl: 'https://iru-portal-core-acc.azurewebsites.net',
  languages: ['en'],
  defaultLanguage: 'en',
  currency: '$',
  defaultUserCustomer: 'Loews',
  numberOfItemsPerRequest: 10,
  numberOfDaysToPrefetchBefore: 0,
  numberOfDaysToPrefetchAfter: 0,
  timeInMsToRefetch: 5000,
  guestArrivalsColumnsOrder: [
    'avatar',
    'name',
    'room',
    'eta',
    'nights',
    'loyalty',
    'lifetime-value',
    'status'
  ],
  guestDetails: {
    dateFormat: 'MMM DD YY',
    secondaryDateFormat: 'MM/dd/yyyy',
    dateFormatWithTime: 'MM/DD/YY HH.MM',
    birthdateFormat: 'YYYY-MM-DD',
    numberOfPreferencesView: 10,
    tabsOrder: ['reservation', 'profile', 'preferences', 'messages', 'lifetime-value']
  },
  expectedOccupancy: [
    // { start: '2019-11-01', className: 'low' },
    // { start: '2019-11-04', className: 'mid' },
    // { start: '2019-11-12', className: 'high' }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
