import { EndpointConfig } from '../core/models/endpoint';

export const EndpointsConfig: EndpointConfig = {
  reservation: {
    search: '/api/v11/reservation/search',
    lifetimeValue: '/api/folio/getbyreservation',
    messages: 'assets/get-messages-response-mock.json' // @ToDo: temporary mock
  },
  authentication: {
    securitySettings: '/api/v1/security/authentication/token/',
    userProviderWithEmail: '/api/v1/security/providers/user-providers?emailAddress=',
    originProviders: '/api/v1/security/providers/origin-providers?origin='
  },
  customer: {
    customerSettings: '/api/v1/core/customers/settings/',
    userCustomers: '/api/v1/core/customers'
  },
  person: {
    predefinedPreferences: '/api/person/objecttypedefinitions',
    update: '/api/person/updateobjects'
  },
  property: {
    property: '/api/property/list'
  }

};
