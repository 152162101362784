import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';

import { RoutesConfig } from './configs/routes.config';
import { AuthGuard } from './core/helpers/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: RoutesConfig.routes.arrivalList, pathMatch: 'full' },
  {
    path: RoutesConfig.routesNames.login,
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: RoutesConfig.routesNames.arrivalList,
    loadChildren: () =>
      import('./modules/arrival-list/arrival-list.module').then(m => m.ArrivalListModule),
    canActivate: [AuthGuard],
    data: { animation: 'ArrivalListPage' }
  },
  {
    path: RoutesConfig.routesNames.guestDetails,
    loadChildren: () =>
      import('./modules/guest-details/guest-details.module').then(m => m.GuestDetailsModule),
    canActivate: [AuthGuard],
    data: { animation: 'GuestDetailsPage' }
  },
  {
    path: '**',
    loadChildren: () => import('./modules/error/error-page.module').then(m => m.ErrorPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), BrowserAnimationsModule],
  exports: [RouterModule]
})
export class AppRoutingModule {}
