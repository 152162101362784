import { Component, Input } from '@angular/core';

@Component({
  selector: 'gea-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent {
  @Input() slides: string[];

  constructor() {}
}
