import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { APP_CONFIG, IAppConfig } from '../../configs/app.config';
import { EndpointsConfig } from '../../configs/endpoints.config';

import { IBaseResponse } from '../models/base-response';
import { StorageService } from './storage/storage.service';
import { storageFields } from './storage/storage.definitions';

@Injectable({ providedIn: 'root' })
export class PropertyService {
  private cloudMwBaseUrl: string;
  constructor(
    private http: HttpClient,
    private storage: StorageService,
    @Inject(APP_CONFIG) private appConfig: IAppConfig
  ) {

  }

}
