import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {
  transform(value: string): string {
    const matches = value.match(/\b(\w)/g);
    return matches.join('');
  }
}
