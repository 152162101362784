import { Component, Input } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'gea-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent {
  @Input() type: string;
  @Input() label: string;
  @Input() name: string;
  @Input() parentForm: FormGroup;
  @Input() errors: ValidationErrors;
  @Input() icon: string;
  constructor() {}
}
