import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StorageService } from '../../../core/services/storage/storage.service';
import { storageFields } from '../../../core/services/storage/storage.definitions';
import { IRouteConfig, ROUTES_CONFIG } from '../../../configs/routes.config';
import { AuthenticationService } from '../../../core/services/authentication/authentication.service';
import { CustomerService } from '../../../core/services/customer.service';
import { PropertyService } from '../../../core/services/property.service';
import { MessageService } from 'src/app/core/services/message.service';

@Component({
  selector: 'gea-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public emailAddress: string;
  public showMenu = false;
  public languages: Array<string>;
  public currentLanguage: string;
  private logo: string;

  private destroySubject$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private customerService: CustomerService,
    private translate: TranslateService,
    private propertyService: PropertyService,
    public storageService: StorageService,
    @Inject(ROUTES_CONFIG) public routesConfig: IRouteConfig
  ) {
    this.initLanguages();
    this.authenticationService.emailAddress.subscribe(ea => (this.emailAddress = ea));
  }

  ngOnInit() {
    this.customerService
      .getCustomThemeImages()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(images => {
        this.logo = images && images.logo ? images.logo : '';
      });
  }

  authenticated(): boolean {
    return this.authenticationService.authenticated();
  }

  toggle(): void {
    this.showMenu = !this.showMenu;
  }

  goHome(): void {
    this.router.navigate([this.routesConfig.routes.arrivalList]);
  }

  logout(): void {
    this.authenticationService.logout();
  }

  setLanguage(lang): void {
    this.translate.use(lang);
  }

  private initLanguages(): void {
    this.currentLanguage = this.translate.defaultLang;

    this.languages = this.translate.getLangs().filter(l => l !== this.currentLanguage);

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.languages = this.translate.getLangs().filter(l => l !== event.lang);
      this.currentLanguage = event.lang;
    });
  }
}
