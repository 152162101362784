import { Component, Host, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { Options } from 'ng5-slider';

import { IFilterConfig } from '../../../configs/filter.config';

@Component({
  selector: 'gea-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss']
})
export class RangeSliderComponent implements OnInit {
  @Input() options: Options;
  @Input() controlName?: string;
  @Input() filterConfig: IFilterConfig;

  public formGroup: FormGroup;

  constructor(@Host() private formGroupDirective: FormGroupDirective) {}

  public ngOnInit(): void {
    this.formGroup = this.formGroupDirective.form;
  }

  public getControl(): FormControl {
    if (this.controlName) {
      return this.formGroup.get(this.controlName) as FormControl;
    }
  }

  public getMin(e): void {
    const min = e.target.value;
    this.getControl().reset([this.convertValueToNumber(min), this.options.ceil]);
  }

  public getMax(e): void {
    const max = e.target.value;
    this.getControl().reset([this.options.floor, this.convertValueToNumber(max)]);
  }

  private convertValueToNumber(str: string) {
    const time = str.split(':');
    return str.includes('$')
      ? Number(str.replace(/^\$/, ''))
      : str.includes(':')
      ? Number(time[0]) * 60 + Number(time[1])
      : Number(str);
  }
}
