import { InjectionToken } from '@angular/core';
import { environment } from '../../environments/environment';

export const APP_CONFIG = new InjectionToken('app.config');

export interface IAppConfig {
  fetchingAndCaching: IFetchingAndCaching;
  portalCoreUrl: string;
  languages: Array<string>;
  defaultLanguage: string;
  guestArrivalsColumnsOrder: Array<string>;
  currency: string;
  defaultUserCustomer: string;
  guestDetails: IGuestDetails;
  expectedOccupancy: IExpectedOccupancy[];
}

export interface IFetchingAndCaching {
  numberOfItemsPerRequest: number;
  numberOfDaysToPrefetchBefore: number;
  numberOfDaysToPrefetchAfter: number;
  timeInMsToRefetch: number;
}

export interface IGuestDetails {
  dateFormat: string;
  secondaryDateFormat: string;
  dateFormatWithTime: string;
  numberOfPreferencesView: number;
  birthdateFormat: string;
  tabsOrder: Array<string>;
}

export interface IExpectedOccupancy {
  start: string;
  className: string;
}

const expectedOccupancy: IExpectedOccupancy[] = environment.expectedOccupancy;

const fetchingAndCaching = {
  numberOfItemsPerRequest: environment.numberOfItemsPerRequest,
  numberOfDaysToPrefetchBefore: environment.numberOfDaysToPrefetchBefore,
  numberOfDaysToPrefetchAfter: environment.numberOfDaysToPrefetchAfter,
  timeInMsToRefetch: environment.timeInMsToRefetch
};

export const guestDetails = {
  dateFormat: environment.guestDetails.dateFormat,
  dateFormatWithTime: environment.guestDetails.dateFormatWithTime,
  birthdateFormat: environment.guestDetails.birthdateFormat,
  numberOfPreferencesView: environment.guestDetails.numberOfPreferencesView,
  tabsOrder: environment.guestDetails.tabsOrder,
  secondaryDateFormat: environment.guestDetails.secondaryDateFormat
};

export const AppConfig: IAppConfig = {
  fetchingAndCaching,
  portalCoreUrl: environment.portalCoreUrl,
  languages: environment.languages,
  defaultLanguage: environment.defaultLanguage,
  currency: environment.currency,
  defaultUserCustomer: environment.defaultUserCustomer,
  guestArrivalsColumnsOrder: environment.guestArrivalsColumnsOrder,
  guestDetails,
  expectedOccupancy
};
